import { makeAutoObservable } from 'mobx';
import { z } from 'zod';

export enum InstallationProviderID {
  GitHub = 'GitHub',
  Azure = 'Azure',
}

const GitHubProviderSchema = z.object({
  id: z.literal(InstallationProviderID.GitHub),
  gitHub: z.object({
    installationId: z.number(),
    ownerId: z.number(),
    owner: z.string(),
    ownerType: z.string(),
  }),
});

const AzureProviderSchema = z.object({
  id: z.literal(InstallationProviderID.Azure),
  azure: z.object({
    tenantId: z.string(),
    organizationId: z.string(),
    organizationName: z.string(),
  }),
});

export const InstallationSchema = z.object({
  name: z.string(),
  avatarUrl: z.string(),
  queryParameters: z.record(z.string(), z.string()),
  provider: z.union([GitHubProviderSchema, AzureProviderSchema]),
});

export type InstallationData = z.infer<typeof InstallationSchema>;

export class Installation {
  readonly _rawData: InstallationData;

  constructor(installationData: InstallationData) {
    makeAutoObservable(this);
    this._rawData = installationData;
  }

  get rawData() {
    return this._rawData;
  }
}
