import { makeAutoObservable } from 'mobx';
import { AuthStore } from './auth-store';
import { SessionStore } from './session-store';
import { SettingsStore } from './settings-store';

export class RootStore {
  _settingsStore: SettingsStore;

  _authStore: AuthStore;

  _sessionStore: SessionStore;

  private constructor(
    settingsStore: SettingsStore,
    authStore: AuthStore,
    sessionStore: SessionStore,
  ) {
    makeAutoObservable(this);
    this._settingsStore = settingsStore;
    this._authStore = authStore;
    this._sessionStore = sessionStore;
  }

  get settingsStore() {
    return this._settingsStore;
  }

  get authStore() {
    return this._authStore;
  }

  get sessionStore() {
    return this._sessionStore;
  }

  static async load() {
    const settingsStore = await SettingsStore.load();
    const authStore = new AuthStore(settingsStore);
    const sessionStore = new SessionStore(settingsStore, authStore);
    return new RootStore(settingsStore, authStore, sessionStore);
  }

  dispose() {
    this._authStore.dispose();
    this._sessionStore.dispose();
  }
}
