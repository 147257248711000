import { ApiProxy } from '@/lib/api-proxy';
import { Installation } from '@/models/installation';
import { InstallationCollection } from '@/models/installation-collection';
import { RepositoryCollection } from '@/models/repository-collection';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import posthog from 'posthog-js';
import { AuthStore } from './auth-store';
import { SettingsStore } from './settings-store';

export class SessionStore {
  _settingsStore: SettingsStore;

  _authStore: AuthStore;

  _installations: InstallationCollection | undefined;

  _currentInstallation: Installation | undefined;

  _apiProxy: ApiProxy | undefined;

  _repositories: RepositoryCollection | undefined;

  #disposers: (() => void)[] = [];

  constructor(settingsStore: SettingsStore, authStore: AuthStore) {
    this._settingsStore = settingsStore;
    this._authStore = authStore;
    makeAutoObservable(this);

    this.#disposers.push(
      reaction(
        () => this.authStore.isLoggedIn,
        async (isLoggedIn) => {
          if (isLoggedIn === true) {
            await this.onLogin();
          } else if (isLoggedIn === false) {
            this.onLogout();
          }
        },
        { fireImmediately: true },
      ),
    );
  }

  get settingsStore() {
    return this._settingsStore;
  }

  get authStore() {
    return this._authStore;
  }

  get installations() {
    return this._installations;
  }

  get apiProxy() {
    return this._apiProxy;
  }

  get currentInstallation() {
    if (!this.installations || this.installations.items.length === 0) {
      this.currentInstallation = undefined;
    } else if (!this._currentInstallation) {
      [this.currentInstallation] = this.installations.items;
    }
    return this._currentInstallation;
  }

  set currentInstallation(installation: Installation | undefined) {
    this._currentInstallation = installation;
    if (
      installation &&
      !this.settingsStore.settings.rawData.__dev__?.service_account_key
    ) {
      posthog.identify(new Date().toUTCString(), {
        name: installation.rawData.name,
      });
    }
  }

  get repositories() {
    return this._repositories;
  }

  private async onLogout() {
    this._apiProxy = undefined;
    this._installations = undefined;
    this._currentInstallation = undefined;
    this._repositories = undefined;
    // posthog.reset();
  }

  private async onLogin() {
    await this.loadInstallations();
    this.loadApiProxy();
    await this.loadRepositories();
  }

  private async loadInstallations() {
    const installations = await InstallationCollection.load(
      this.settingsStore.settings.rawData.apiUrl,
      this.settingsStore.settings.rawData.__dev__?.service_account_key,
    );
    runInAction(() => {
      this._installations = installations;
    });
  }

  private loadApiProxy() {
    if (!this.currentInstallation) {
      return;
    }
    this._apiProxy = new ApiProxy(
      this.settingsStore.settings.rawData.apiUrl,
      this.currentInstallation?.rawData.queryParameters,
      this.settingsStore.settings.rawData.__dev__?.service_account_key,
    );
  }

  private async loadRepositories() {
    if (!this._apiProxy) {
      return;
    }
    const repositories = await RepositoryCollection.load(this._apiProxy);
    runInAction(() => {
      this._repositories = repositories;
    });
  }

  dispose() {
    this.#disposers.forEach((disposer) => disposer());
  }
}
