import { create } from 'zustand';
import { RootStore } from './root-store';

interface ContextState {
  rootStore: RootStore | null;
  setRootStore: (rootStore: RootStore) => void;
}

export const ContextStateStore = create<ContextState>((set) => ({
  rootStore: null,
  setRootStore: (rootStore: RootStore) => set({ rootStore }),
}));
