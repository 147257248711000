import { Paths } from '@/lib/types';
import { makeAutoObservable } from 'mobx';
import { z } from 'zod';

export enum GitProviderID {
  GitHub = 'GitHub',
  Azure = 'Azure',
}

const GitHubGitRepositorySchema = z.object({
  owner: z.string(),
  ownerId: z.number(),
  repositoryName: z.string(),
  repositoryId: z.number(),
  hasIssue: z.boolean(),
});

const BitBucketGitRepositorySchema = z.object({
  repositoryId: z.string(),
  repositoryName: z.string(),
  projectKey: z.string(),
  projectName: z.string(),
});

const GitLabGitRepositorySchema = z.object({}); // Assuming no fields for simplification

const AzureGitRepositorySchema = z.object({
  projectId: z.string(),
  projectName: z.string(),
  repositoryId: z.string(),
  repositoryName: z.string(),
});

const GitRepositoryProviderSchema = z.object({
  id: z.nativeEnum(GitProviderID),
  github: GitHubGitRepositorySchema.optional(),
  bitbucket: BitBucketGitRepositorySchema.optional(),
  gitlab: GitLabGitRepositorySchema.optional(),
  azure: AzureGitRepositorySchema.optional(),
});

export const RepositorySchema = z.object({
  provider: GitRepositoryProviderSchema,
  id: z.string(),
  name: z.string(),
  fullName: z.string(),
  isGlobalConfig: z.boolean(),
  hasIssues: z.boolean(),
  isPublic: z.boolean(),
  isInitialized: z.boolean(),
});

export type RepositoryData = z.infer<typeof RepositorySchema>;

export const RepositoryDataKeyTitleMap: {
  [K in Paths<RepositoryData>]: string;
} = {
  id: 'ID',
  name: 'Name',
  fullName: 'Full Name',
  isGlobalConfig: 'Is Global Config Repository?',
  hasIssues: 'Has Issues?',
  isPublic: 'Is Public?',
  isInitialized: 'Is Initialized?',
  provider: 'Provider',
  'provider.undefined': 'Undefined Provider',
  'provider.github': 'GitHub Provider Details',
  'provider.bitbucket': 'BitBucket Provider Details',
  'provider.gitlab': 'GitLab Provider Details',
  'provider.azure': 'Azure Provider Details',
  'provider.id': 'Provider ID',
  'provider.github.owner': 'GitHub Owner',
  'provider.github.ownerId': 'GitHub Owner ID',
  'provider.github.repositoryName': 'GitHub Repository Name',
  'provider.github.repositoryId': 'GitHub Repository ID',
  'provider.github.hasIssue': 'GitHub Has Issue',
  'provider.bitbucket.repositoryId': 'BitBucket Repository ID',
  'provider.bitbucket.repositoryName': 'BitBucket Repository Name',
  'provider.bitbucket.projectKey': 'BitBucket Project Key',
  'provider.bitbucket.projectName': 'BitBucket Project Name',
  'provider.azure.projectId': 'Azure Project ID',
  'provider.azure.projectName': 'Azure Project Name',
  'provider.azure.repositoryId': 'Azure Repository ID',
  'provider.azure.repositoryName': 'Azure Repository Name',
};

export class Repository {
  readonly _rawData: RepositoryData;

  constructor(installationData: RepositoryData) {
    makeAutoObservable(this);
    this._rawData = installationData;
  }

  get rawData() {
    return this._rawData;
  }
}
