'use client';

import { useStore } from '@/lib/zustand';
import { ContextStateStore } from '@/stores/context-state-store';
import { RootStore } from '@/stores/root-store';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

// React context (useContext) was supposed to be used here. However, it doesn't work well with Next.js:
// Every link click (even when using the next/link import) will trigger a re-render of RootStoreProvider
// and thus the rootStore will be disposed and re-created.
// As such, we are using zustand to manage the context state instead.
export const RootStoreProvider = observer(
  ({ children }: { children: React.ReactNode }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const contextStateStore = useStore(ContextStateStore, (state) => state);
    const contextStateStoreRef = useRef(contextStateStore);

    useEffect(() => {
      const loadRootStore = async () => {
        if (!contextStateStoreRef.current.rootStore) {
          const store = await RootStore.load();
          contextStateStoreRef.current.setRootStore(store);
        }
        setIsLoaded(true);
      };
      loadRootStore();
      // should only run once per load
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isLoaded ? <>{children}</> : <></>;
  },
);

export function useRootStore() {
  const contextStateStore = useStore(ContextStateStore, (state) => state);

  if (!contextStateStore.rootStore) {
    throw new Error(
      'RootStore not initialized: useRootStore must be used within RootStoreProvider',
    );
  }

  return contextStateStore.rootStore;
}
