import { ApiProxy } from '@/lib/api-proxy';
import { makeAutoObservable } from 'mobx';
import { z } from 'zod';
import { Repository, RepositorySchema } from './repository';

const RepositoriesApiResponseSchema = z.object({
  repositories: z.array(RepositorySchema),
});
export type RepositoriesDTO = z.infer<typeof RepositoriesApiResponseSchema>;

export class RepositoryCollection {
  _items: Repository[];

  private constructor(repositoriesDTO: RepositoriesDTO) {
    makeAutoObservable(this);
    this._items = repositoriesDTO.repositories.map(
      (repository) => new Repository(repository),
    );
  }

  get items() {
    return this._items;
  }

  get rawData() {
    return this.items.map((item) => item.rawData);
  }

  async initialize(apiProxy: ApiProxy, repositoryIds: string[]) {
    return apiProxy.post('/admin/repositories/initialize', {
      repositories: [
        ...repositoryIds.map(
          (id) =>
            this.rawData.find((repository) => repository.id === id)?.provider,
        ),
      ],
    });
  }

  async uninitialize(apiProxy: ApiProxy, repositoryIds: string[]) {
    return apiProxy.post('/admin/repositories/uninitialize', {
      repositories: [
        ...repositoryIds.map(
          (id) =>
            this.rawData.find((repository) => repository.id === id)?.provider,
        ),
      ],
    });
  }

  async reload(apiProxy: ApiProxy) {
    const repositoriesDTO = await RepositoryCollection.loadData(apiProxy);
    this._items = repositoriesDTO.repositories.map(
      (repository) => new Repository(repository),
    );
  }

  private static async loadData(apiProxy: ApiProxy): Promise<RepositoriesDTO> {
    const response = await apiProxy.get('/admin/repositories', undefined, {
      cacheSeconds: 0,
    });
    return RepositoriesApiResponseSchema.parse(response);
  }

  static async load(apiProxy: ApiProxy): Promise<RepositoryCollection> {
    return new RepositoryCollection(
      await RepositoryCollection.loadData(apiProxy),
    );
  }
}
