import { refreshToken } from '@/lib/auth';
import { makeAutoObservable, reaction } from 'mobx';
import { SettingsStore } from './settings-store';

export class AuthStore {
  _isLoggedIn: boolean | undefined = undefined;

  _settingsStore: SettingsStore;

  #disposers: (() => void)[] = [];

  constructor(settingsStore: SettingsStore) {
    makeAutoObservable(this);
    this._settingsStore = settingsStore;

    if (this._settingsStore.settings.rawData.__dev__?.service_account_key) {
      this.setLoggedIn();
    } else {
      const refreshFn = () => {
        if (this.isLoggedIn === true || this.isLoggedIn === undefined) {
          refreshToken(
            this._settingsStore.settings.rawData.apiUrl,
            () => {
              this.setLoggedIn();
            },
            () => {
              this.setLoggedOut();
            },
          );
        }
      };

      refreshFn();
      setInterval(
        refreshFn,
        (this._settingsStore.settings.rawData.accessTokenExpiration / 2) * 1000,
      );
    }

    this.#disposers.push(
      reaction(
        () => this.isLoggedIn,
        (isLoggedIn) => {
          if (
            isLoggedIn === false &&
            !window.location.pathname.startsWith('/login') &&
            !window.location.pathname.startsWith('/callback')
          ) {
            window.location.href = '/login';
          }
        },
        { fireImmediately: true },
      ),
    );
  }

  get isLoggedIn() {
    return this._isLoggedIn;
  }

  setLoggedIn() {
    this._isLoggedIn = true;
  }

  setLoggedOut() {
    this._isLoggedIn = false;
  }

  dispose() {
    this.#disposers.forEach((disposer) => disposer());
  }
}
