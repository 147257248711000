'use client';

import { makeAutoObservable } from 'mobx';
import { z } from 'zod';

const SettingsSchema = z.object({
  apiUrl: z.string(),
  authUrl: z.string(),
  clientId: z.string(),
  accessTokenExpiration: z.number(),
  posthog: z
    .object({
      apiHost: z.string(),
      apiKey: z.string(),
    })
    .optional(),
  identityProviders: z.array(
    z.object({
      id: z.string(),
      identityProviderName: z.string(),
    }),
  ),
  featureFlags: z.object({
    chat: z.boolean().optional().default(false),
    events_v2: z.boolean().optional().default(false),
  }),
  __dev__: z
    .object({
      service_account_key: z.string().optional(),
    })
    .optional(),
});

export enum IdentityProvider {
  GITHUB = 'GITHUB',
  AZURE = 'AZURE',
}

export type SettingsData = z.infer<typeof SettingsSchema>;

export class Settings {
  _rawData: SettingsData;

  private static promisedSettings: Promise<Settings> | null = null;

  private constructor(settingsData: SettingsData) {
    makeAutoObservable(this);
    this._rawData = settingsData;
  }

  get rawData() {
    return this._rawData;
  }

  static load(url: string = '/settings.json'): Promise<Settings> {
    if (!Settings.promisedSettings) {
      Settings.promisedSettings = fetch(url)
        .then((response) => response.json())
        .then((body) => {
          const settings = Settings.fromJSON(body);
          Settings.promisedSettings = Promise.resolve(settings);
          return settings;
        });
    }

    return Settings.promisedSettings;
  }

  static fromJSON(json: any): Settings {
    const settingsData = SettingsSchema.parse(json);
    return new Settings(settingsData);
  }
}
